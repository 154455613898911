import request from '@/utils/request'

export function getList(params) {
  return request({
    url: 'api/frontend/liunian/apps',
    method: 'get',
    params
  })
}
export function getTemplate(params) {
  return request({
    url: '/api/frontend/liunian/app_moban',
    method: 'get',
    params
  })
}

export function saveTemplate(data) {
  return request({
    url: '/api/frontend/liunian/user_post',
    method: 'post',
    data
  })
}
export function userPosts(params) {
  return request({
    url: '/api/frontend/liunian/user_posts',
    method: 'get',
    params
  })
}
export function appTab(params) {
  return request({
    url: '/api/frontend/liunian/app_tab',
    method: 'get',
    params
  })
}
export function wangpan(params) {
  return request({
    url: '/api/frontend/wangpan/search',
    method: 'get',
    params
  })
}
export function category(params) {
  return request({
    url: '/api/frontend/wangpan/category',
    method: 'get',
    params
  })
}

<template>
  <div class="hello">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" style="height: 182px;">
      <van-swipe-item>
        <van-image
            src="https://cdn.doumvip.com/WechatIMG48.png"
        />
      </van-swipe-item>
    </van-swipe>
    <van-grid style="margin-top: 20px;padding-bottom: 50px;" :border="true" :gutter="10" :column-num="2" class = "vant-girl" v-if="dataList.length">
       <van-grid-item text="文字"  @click="showPopup(item.app_id)" v-for="(item,index) in dataList" :key="index">
        <div class="view-content">
          <van-image round :src="item.app.microapp_logo" style="height: 100px; "/>
        </div>
        <van-cell :title="item.app.microapp_name" />
      </van-grid-item >
    </van-grid>
    <van-empty v-else description="暂无数据" />
    <van-popup v-model="show" position="bottom" :style="{ height: '80%' }" >
      <div class="dialog-title">选择模板</div>
      <van-form >
        <van-field
            v-model="params.keyword"
            name="输入口令"
            label="输入口令"
            placeholder="输入口令"
            :rules="[{ required: true, message: '请输入口令' }]"
        />
        <!-- <van-radio-group v-model="radio" direction="horizontal" @change="handelChange" v-if="templateList.length">
          <van-grid :border="true" :gutter="13" :column-num="1" >
            <van-grid-item v-for="item in templateList" :key="item.id">
              <van-radio :name="item.id" >
                <div class="radio">
                <van-image
                    width="120"
                    height="120"
                    :src="item.image"
                />
                <div class="textTitle">{{ item.name }}</div>
                </div>
              </van-radio>
            </van-grid-item>
            <div v-for="item in templateList" :key="item.id">
                <div  :class="['radio',{'active':radioActive ==item.id}]" @click="changeRadio(item.id)">
                    <van-image
                        width="120"
                        height="120"
                        :src="item.image"
                    />
                    <div class="textTitle">{{ item.name }}</div>
                </div>
            </div>  
          </van-grid>
        </van-radio-group> -->
        <div v-if="templateList.length" class="moban-content">
          <div v-for="item in templateList" :key="item.id">
                <div  :class="['radio',{'active':radioActive ==item.id}]" @click="changeRadio(item.id)">
                    <van-image
                        width="80"
                        height="80"
                        :src="item.image"
                    />
                    <div class="textTitle">{{ item.name }}</div>
                </div>
            </div>  
        </div>
        <van-empty v-else description="暂无数据" />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit" @click="save">提交</van-button>
        </div>
      </van-form>


    </van-popup>
  </div>
</template>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
}

.vant-girl {
  padding-left: 10px;
  padding-bottom: 20px
}
</style>
<script>
import { Notify,Dialog } from 'vant';
import { getList,getTemplate, saveTemplate} from '@/api/index'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      dataList:[],
      appId:null,
      templateList:[],
      params:{
        mobanId:null,
        keyword:null,
      },
      show: false,
      radio: null,
      username:'',
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg',
      ],
      radioActive:null,
      loading:false
    };
  },
  created(){
    this.getList()
  },
  methods: {
    changeRadio(id){
      this.radioActive = id
      this.params.mobanId = id
    },  
    showPopup(appId) {
      this.show = true;
      this.appId = appId
      this.getTemplate()
    },
  async  getList(){
     await getList().then(response => {
        this.dataList = response.data;
      })
    },
   async  getTemplate(){
    const { data : res } = await getTemplate({appId: this.appId})
    this.templateList =  res
  },

  async save(){
    if(!this.params.keyword){
      return   Dialog.alert({message: '口令不能为空',}).then(() => { });
    }else if(!this.params.mobanId){
      return Dialog.alert({  message: '模板不能为空',  }).then(() => { });
    }
    this.loading = true
    try {
      const res = await saveTemplate(this.params)
      console.log(res,'msgmsgmsgmsg');
        if(res.msg == 'ok'){
          this.loading = false
          this.$router.push('/my')
        }else{
          Dialog.alert({message: res.msg,}).then(() => { });
        }
      } catch (err) {
        Dialog.alert({message: err,}).then(() => { });
      }
  },
    handelChange(e){
      console.log(e,'handelChange');
      this.params.mobanId = e
    }
  },
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.data-content{
  padding: 16px ;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.data-item{
  width: 40%;
  height: 80px;
  padding: 16px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f2fc;
  border-radius: 20px;
}
.dialog-title{
  height: 60px;
  line-height: 60px;
  text-align: center;
  // background-image: linear-gradient(to right bottom,#e7effd, #dce7fb);
}
.textTitle{
  margin-left: 10px;
  margin-bottom: 10px;
}
.radio {
  margin-bottom:10px ;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
}
.active{
  background-color: #e7effd;
}
.moban-content{
  margin: 10px 20px;
}
::v-deep .van-grid-item__content{
  padding: 5px 8px;
}
::v-deep .van-radio{
  width: 100%;
  padding: 5px;
  // flex-direction: column-reverse;
  background-color: #e5effd !important;
  border-radius: 5%;
}
::v-deep .van-cell{
  font-size: 16px;
}
::v-deep .van-radio__label{
  margin-left: 0;
}
.view-content{
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f3fc !important;
  border-radius: 5%;
}

</style>

